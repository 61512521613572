import payload_plugin_qGj32UB0vL from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_SUdAVK1CtQ from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_X1ZcwlcPAM from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_upfh22fcfB from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_z1i7VCz7Kv from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HtezVRgpVw from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_L1U9W0tQMS from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_QbtrEESjtI from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_sA0pmCutfg from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qygSrRkr6W from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/smz/smz/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_o4KCA1pfCp from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._yt4dye2n4hydaa4opck6vlw2vm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_tabler_icons_9mYbrP432H from "/home/runner/work/smz/smz/frontend/plugins/01.tabler-icons.ts";
import _02_vuetify_rwZ6VQqJQJ from "/home/runner/work/smz/smz/frontend/plugins/02.vuetify.ts";
import scrollbar_client_afVkuO58C3 from "/home/runner/work/smz/smz/frontend/plugins/scrollbar.client.ts";
import user_client_pgylpKXiFi from "/home/runner/work/smz/smz/frontend/plugins/user.client.ts";
export default [
  payload_plugin_qGj32UB0vL,
  revive_payload_client_SUdAVK1CtQ,
  unhead_X1ZcwlcPAM,
  router_upfh22fcfB,
  payload_client_z1i7VCz7Kv,
  navigation_repaint_client_HtezVRgpVw,
  check_outdated_build_client_L1U9W0tQMS,
  view_transitions_client_QbtrEESjtI,
  chunk_reload_client_sA0pmCutfg,
  plugin_vue3_qygSrRkr6W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_o4KCA1pfCp,
  plugin_wy0B721ODc,
  _01_tabler_icons_9mYbrP432H,
  _02_vuetify_rwZ6VQqJQJ,
  scrollbar_client_afVkuO58C3,
  user_client_pgylpKXiFi
]