import { DARK_THEME } from '@/plugins/theme/DarkTheme'
import { LIGHT_THEME } from '@/plugins/theme/LightTheme'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'

// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

export default defineNuxtPlugin({
  name: 'vuetify',
  parallel: true,
  async setup(nuxtApp) {
    const vuetify = createVuetify({
    /* components, directives, */
      ssr: true,
      theme: {
        defaultTheme: 'dark',
        // defaultTheme: "BLUE_THEME",
        themes: {
          light: LIGHT_THEME,
          dark: DARK_THEME,
        },
      },
      defaults: {
        VCard: {
          rounded: 'lg',
        },
        VListItem: {
          minHeight: '45px',
        },
        VTooltip: {
          location: 'top',
        },
      },
    })
    nuxtApp.vueApp.use(vuetify)
  },
})
