import type { User, UserPrincipal } from '~/api/auth'
import type { TokenInfo } from '~/api/auth/models/token-info'
import {
  getToken,
  setUser,
  targetSecuredUrlPathFromHistory,
  updateTokens,
  useUser,
} from '~/composables/auth/use-customer-info'

export function useAuth() {
  async function loginWith(userCreds: UserPrincipal) {
    const tokenInfo: TokenInfo = await $fetch<TokenInfo>(`${useRuntimeConfig().public.API_URL}/auth/login`, {
      method: `POST`,
      body: userCreds,
    })

    await loginWithTokens(tokenInfo)
  }

  async function loginWithTokens(tokenInfo: TokenInfo) {
    await updateTokens(tokenInfo)
    await me()

    navigateTo(targetSecuredUrlPathFromHistory().value)
  }

  async function logout() {
    await $fetch(`${useRuntimeConfig().public.API_URL}/auth/logout`, {
      method: `POST`,
    })
    await setUser(undefined)
    navigateTo('/')
  }

  async function loadMe() {
    return useUser().value ? useUser().value : await me()
  }

  async function me() {
    const token = await getToken()
    if (!token) {
      // console.debug('No token to fetch user')
      return
    }
    try {
      const user: User = await $fetch<User>(`${useRuntimeConfig().public.API_URL}/auth/user`, {
        headers: { ...useRequestHeaders([`cookie`]) as HeadersInit, Authorization: token },
      })
      setUser(user)
      return user
    }
    catch (error) {
      console.error(`❌ Error getting user with token = ${token} 🔴`, error)
      return undefined
    }
  }

  return {
    loginWithTokens,
    loginWith,
    logout,
    loadMe,
  }
}
