<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps<{ error: NuxtError }>()

definePageMeta({
  layout: 'default',
})

function handleError() {
  clearError({ redirect: '/' })
}
</script>

<template>
  <NuxtLayout>
    <v-empty-state
      image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
      size="200"
      text-width="250"
    >
      <template #media>
        <v-img class="mb-8" />
      </template>

      <template #title>
        <div class="text-h3 text-high-emphasis">
          Oops 😬
        </div>
      </template>

      <template #text>
        <div class="text-body-1">
          {{ error.message ?? error.statusMessage ?? error.statusCode }}
        </div>
      </template>

      <template #actions>
        <v-btn color="primary" @click="handleError">
          Go to main page
        </v-btn>
      </template>
    </v-empty-state>
  </NuxtLayout>
</template>
